import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";

const navLinkStyle = {
    color: 'rgba(0,0,0,0.84)',
    fontSize: '16px',
    fontWeight: '600',
    textDecoration:'none',
    marginLeft:10,
};

const activeLinkStyle = {
    color: '#0c82f8' // Aktif olan linki mavi yapar
};

function AppNavbar() {
    const location = useLocation();

    return (
        <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #f0f0f0', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }} variant="white">
            <Container>
                <Navbar.Brand href="/" style={navLinkStyle}>
                    <img src={require("../assets/cenk-logo.png")} alt="Logo" style={{ maxWidth: '180px', height: 'auto' }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto" style={{ gap: '20px' }}>
                        <Link to="/" style={{...navLinkStyle, ...(location.pathname === '/' && activeLinkStyle)}}>Anasayfa</Link>
                        <Link to="/cikmis-sorular" style={{...navLinkStyle, ...(location.pathname === '/cikmis-sorular' && activeLinkStyle)}}>Çıkmış Sorular</Link>
                        <Link to="/konu-testleri" style={{...navLinkStyle, ...(location.pathname === '/konu-testleri' && activeLinkStyle)}}>Konu Testleri</Link>

                        <Link to="/ders-konulari" style={{...navLinkStyle, ...(location.pathname === '/ders-konulari' && activeLinkStyle)}}>Ders Notları</Link>
                        <Link to="/bloglar" style={{...navLinkStyle, ...(location.pathname === '/bloglar' && activeLinkStyle)}}>Bloglar</Link>
                        <Link to="/trafik-isaretleri" style={{...navLinkStyle, ...(location.pathname === '/trafik-isaretleri' && activeLinkStyle)}}>Trafik İşaretleri</Link>
                        <Link to="/hiz-kurallari" style={{...navLinkStyle, ...(location.pathname === '/hiz-kurallari' && activeLinkStyle)}}>Hız Kuralları</Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AppNavbar;
