import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Banner from "../../Component/Banner";
import {Container,Row,Col} from "react-bootstrap";
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'; // Link componentini import edin





const CikmisSorularPage = () => {
    const [kategoriler, setKategoriler] = useState([]);
    const [showAll, setShowAll] = useState(false); // Verilerin tamamını gösterme durumu


    useEffect(() => {
        const apiUrl = "https://panel.mebehliyet.com/api/data/sorukategoriler";
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                setKategoriler(data);
            })
            .catch((error) => {
                console.error("API'den veri alınırken bir hata oluştu:", error);
            });
    }, []);


    const containerStyle = {
        padding: "40px 20px",
        borderRadius: "10px",
    };

    const cardStyle = {
        backgroundColor: "#fdfdfd",
        borderWidth: "1px",
        borderColor:'#dcdcdc',
        borderRadius: "10px",
        boxShadow: "0px 2px 4px rgba(23, 162, 184, 0.2)",
    };

    // Maksimum 5 veri göster, showAll true olursa tamamını göster
    const visibleKategoriler = showAll ? kategoriler : kategoriler.slice(0, 10);





    return (
        <div>
            <Helmet>
                <title>Çıkmış Ehliyet Soruları - En Güncel Sınav Soruları</title>
                <meta name="description" content="Ehliyet sınavına hazırlanmak için en güncel çıkmış soruları burada bulabilirsiniz. Trafik, motor, ilk yardım ve trafik adabı konularında geçmiş yıllara ait sorularla pratik yapın!" />
                <meta name="keywords" content="çıkmış ehliyet soruları, ehliyet sınavı soruları, ehliyet deneme sınavı, ehliyet testi, trafik soruları, motor soruları, ilk yardım soruları" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.mebehliyet.com/cikmis-sorular" />
            </Helmet>
            <Banner title="Çıkmış Sorular"/>

            <div className="container text-center mt-2 mb-5" style={containerStyle}>
                <h2 className="fw-bold text-dark">📌 Ehliyet Çıkmış Sorular</h2>

                <div className="list-group mx-auto mt-4">
                    {visibleKategoriler.map((q, index) => {
                        const baslik = q.slug; // SEF URL başlığı oluştur

                        return (
                            <div key={index} className="list-group-item d-flex justify-content-between align-items-center p-4 mt-3"
                                 style={{backgroundColor: '#ffffff', borderWidth: '1px', borderColor: '#bcbcb9', borderRadius:10}}>
                                <div>
                                    <h5 className="mt-1 mb-1 text-dark">{q.baslik}</h5>
                                </div>
                                <Link
                                    to={`/${baslik}-${q.id}`}
                                    className="btn btn-sade"
                                >
                                    Çözmeye Başla
                                </Link>
                            </div>
                        );
                    })}
                </div>

                {kategoriler.length > 5 && (
                    <button
                        className="btn btn-sade mt-4 px-5 py-3 fw-bold"
                        onClick={() => setShowAll(!showAll)}
                    >
                        {showAll ? "👆 Daha Azını Göster" : "📚 Daha Fazlasını Keşfet"}
                    </button>
                )}
            </div>



        </div>
    );
};

export default CikmisSorularPage;
