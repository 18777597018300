import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Banner from "../../Component/Banner";
import {Helmet} from "react-helmet";



const BlogDetay = () => {
    const navigate = useNavigate();

    const { slug } = useParams();
    const id = slug.split("-").pop(); // URL'den id'yi çıkar
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);

    console.log(id)
    useEffect(() => {
        const fetchBlogDetay = async () => {
            try {
                const response = await axios.get(`https://panel.mebehliyet.com/api/data/blog/${id}`);

                if (response.data) {
                    setBlog(response.data);
                } else {
                    console.error("Blog verisi eksik:", response.data);
                }
            } catch (error) {
                console.error("Blog detayı çekerken hata oluştu:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogDetay();
    }, [id]);

    if (loading) {
        return (
            <div className="container text-center my-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                </div>
            </div>
        );
    }

    if (!blog) {
        return <p className="text-center">⚠️ Blog bulunamadı.</p>;
    }

    // 📌 Doğru slug olup olmadığını kontrol et



    return (
        <div>
            <Helmet>
                <title>{blog.title}</title>
                <meta name="description" content={blog.description} />
                <meta name="keywords" content={blog.keywords} />
            </Helmet>

            <Banner title={blog.title || "Blog"} />

            <div style={{ marginTop: "100px" }} className="container">
                {blog.resimyol && (
                    <img
                        src={`https://panel.mebehliyet.com/${blog.resimyol}`}
                        alt={blog.resimyol_alt || blog.title}
                        className="img-fluid mb-4"
                    />
                )}
                <h2 className="mb-4">{blog.title || "Başlık Bulunamadı"}</h2>

                <p className="text-muted">📅 Yayınlanma Tarihi: {new Date(blog.created_at).toLocaleDateString()}</p>

                {/* Blog içerikleri */}
                <div>
                    {blog.contents && blog.contents.length > 0 ? (
                        blog.contents.map((icerik, index) => {
                            if (icerik.type === "paragraph") {
                                return (
                                    <p
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                            __html: icerik.text.replace(/\r\n/g, "<br />"),
                                        }}
                                    />
                                );
                            }
                            return null;
                        })
                    ) : (
                        <p>📌 Bu blog içeriği henüz eklenmedi.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlogDetay;
