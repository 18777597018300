import 'bootstrap/dist/css/bootstrap.min.css';
import AppNavbar from "./Component/Navbar";
import Footer from "./Component/Footer";
import CikmisSorularPage from "./Pages/CikmisSorular/CikmisSorularPage";
import React from "react";
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import Home from "./Home";
import PolisIsaretleri from "./Pages/Konular/PolisIsaretleri";
import EhliyetSiniflari from "./Pages/Konular/EhliyetSiniflari";
import TrafikIsaretleri from "./Pages/Konular/TrafikIsaretleri";
import HizLimitleri from "./Pages/Konular/HizLimitleri";
import Quiz from "./Pages/CikmisSorular/Quiz";
import ScrollToTopOnMount from "./Component/ScrollToTopOnMount";
import DersKonulari from "./Pages/Konular/DersKonulari";
import Gizlilik_sartlari from "./Pages/Sayfalar/gizlilik_sartlari";
import KullanimKosullari from "./Pages/Sayfalar/kullanim_kosullari";
import CerezPolitikasi from "./Pages/Sayfalar/cerez_politikasi";
import Faq from "./Pages/Sayfalar/faq";
import TestCoz from "./Pages/Testcoz/TestCoz";
import './style.css';
import Result from "./Pages/CikmisSorular/Result";
import KonuTestleriPage from "./Pages/KonuTestleri/KonuTestleriPage";
import TestKategoriPage from "./Pages/KonuTestleri/TestKategoriPage";
import KonuTestCoz from "./Pages/KonuTestleri/KonuTestCoz";
import KonuTestResult from "./Pages/KonuTestleri/KonuTestResult";
import BlogDetay from "./Pages/Sayfalar/BlogDetay";
import Bloglar from "./Pages/Sayfalar/Bloglar";

function App() {
  return (
      <Router>
          <ScrollToTopOnMount />

          <div>
              <AppNavbar/>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/cikmis-sorular" element={<CikmisSorularPage />} />
                  <Route path="/ders-konulari" element={<DersKonulari />} />
                  <Route path="/ehliyet-siniflari" element={<EhliyetSiniflari />} />
                  <Route path="/trafik-isaretleri" element={<TrafikIsaretleri />} />
                  <Route path="/hiz-kurallari" element={<HizLimitleri />} />
                  <Route path="/polis-isaretleri" element={<PolisIsaretleri />} />

                  <Route path="/gizlilik-politikasi" element={<Gizlilik_sartlari />} />
                  <Route path="/kullanim-kosullari" element={<KullanimKosullari />} />
                  <Route path="/cerez-politikasi" element={<CerezPolitikasi />} />
                  <Route path="/sss" element={<Faq />} />



                  <Route path="/:baslik" element={<Quiz />} />


                  <Route path="/soru-coz" element={<TestCoz />} />

                  <Route path="/result" element={<Result />} />

                  <Route path="/konu-testleri" element={<KonuTestleriPage />} />

                  <Route path="/test/:slug/:id" element={<TestKategoriPage />} />

                  <Route path="/konu-test-coz/:slug/:id" element={<KonuTestCoz />} />


                  <Route path="/konu-test-sonuc" element={<KonuTestResult />} />


                  <Route path="/bloglar" element={<Bloglar />} />
                  <Route path="/blog/:slug" element={<BlogDetay />} />



              </Routes>
              <Footer/>


          </div>
      </Router>

  );
}

export default App;
