import React, { useState, useEffect } from 'react';
import Banner from "../../Component/Banner";
import { Helmet } from "react-helmet";

const DersKonulari = () => {
    const [dersKonulari, setDersKonulari] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const apiUrl = "https://panel.mebehliyet.com/api/data/dersliklericek";
        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Veri alınırken hata oluştu.");
                }
                return response.json();
            })
            .then((data) => {
                setDersKonulari(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("API hatası:", error);
                setError(error.message);
                setLoading(false);
            });
    }, []);

    const getKategoriBilgisi = (bicim) => {
        switch (bicim) {
            case 1:
                return { baslik: "Trafik ve Çevre Bilgisi Dersleri", renk: "#d1ecf1", textColor: "#0c5460" }; // Açık mavi
            case 2:
                return { baslik: "İlk Yardım Dersleri", renk: "#d4edda", textColor: "#155724" }; // Açık yeşil
            case 3:
                return { baslik: "Motor ve Teknik Dersleri", renk: "#fff3cd", textColor: "#856404" }; // Açık sarı
            case 4:
                return { baslik: "Trafik Adabı Dersleri", renk: "#f8d7da", textColor: "#721c24" }; // Açık turuncu
            default:
                return { baslik: "Özel Kategori Dersleri", renk: "#e2e3e5", textColor: "#383d41" }; // Açık gri
        }
    };

    return (
        <div>
            <Helmet>
                <title>Ders Konuları - Ehliyet Sınavı İçin Gerekli Bilgiler</title>
                <meta name="description" content="Ehliyet sınavına hazırlanmak için trafik, motor, ilk yardım ve trafik adabı ders konularını inceleyin. Tüm ehliyet sınavı müfredatı burada!" />
                <meta name="keywords" content="ehliyet ders konuları, trafik bilgisi, motor bilgisi, ilk yardım, trafik adabı, sürücü belgesi, ehliyet sınavı konuları, ehliyet müfredatı" />

            </Helmet>
            <Banner title="Ders Konuları" />

            <div className="container my-5">
                {loading && <p>Yükleniyor...</p>}
                {error && <p className="text-danger">Hata: {error}</p>}

                {!loading && !error && (
                    [1, 2, 3, 4].map((bicim) => {
                        const filteredKonular = dersKonulari.filter((konu) => konu.bicim === bicim);
                        if (filteredKonular.length === 0) return null;

                        const kategori = getKategoriBilgisi(bicim);

                        return (
                            <div key={bicim} className="mb-5">
                                {/* Kategori Başlığı */}
                                <h2
                                    className="fw-bold p-3 rounded"
                                    style={{
                                        backgroundColor: kategori.renk,
                                        color: kategori.textColor,
                                    }}
                                >
                                    {kategori.baslik}
                                </h2>

                                {/* Kategoriye ait konular */}
                                <div
                                    className="p-4"
                                    style={{
                                        backgroundColor: kategori.renk + "33", // Şeffaf ton
                                        borderRadius: "10px",
                                    }}
                                >
                                    {filteredKonular.map((konu) => (
                                        <div
                                            key={konu.id}
                                            className="mb-3 bg-white rounded d-flex align-items-center p-3"
                                            style={{
                                                boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                                            }}
                                        >
                                            {/* Sol tarafta resim */}
                                            <img
                                                src={`https://img.youtube.com/vi/${konu.resimyol}/mqdefault.jpg`}
                                                alt={konu.baslik}
                                                className="rounded me-3"
                                                style={{ width: "160px", height: "80px", objectFit: "cover" }}
                                            />

                                            {/* Başlık */}
                                            <h5 className="mb-0 fw-semibold me-auto">{konu.baslik}</h5>

                                            {/* Sağ tarafta Ders Git linki */}
                                            <a target="_blank" href={konu.linkyol} className="btn btn-outline-danger">
                                                Derse Git
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default DersKonulari;
