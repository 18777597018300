import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsArrowRight } from "react-icons/bs"; // React Icons kullanımı

import ilkYardimImg from "../assets/kategoriler/ilkyardim.png";
import trafikImg from "../assets/kategoriler/trafikvecevre.png";
import motorImg from "../assets/kategoriler/motorveteknik.png";
import trafikAdabiImg from "../assets/kategoriler/trafikadabi.png";
import {Link} from "react-router-dom";

const TestBasliklar = () => {
    const konular = [

        {
            id: 1,
            baslik: "Trafik ve Çevre Bilgisi",
            slug: "trafik-ve-cevre-bilgisi",
            aciklama:
                "Trafik bilginizi ölçmek ve sınavlara en iyi şekilde hazırlanmak için en güncel ve kapsamlı trafik sorularıyla pratik yaparak öğrenin! 🚗💨.",
            butonYazisi: "Test Çöz",
            img: trafikImg,
        },
        {
            id: 2,
            baslik: "İlk Yardım Bilgisi",
            slug: "ilk-yardim-bilgisi",
            aciklama:"İlk yardım bilginizi ölçmek ve sınavlara en iyi şekilde hazırlanmak için en güncel ve kapsamlı ilk yardım sorularıyla pratik yapın! 🚑📚",
            butonYazisi: "Test Çöz",
            img: ilkYardimImg,
        },
        {
            id: 3,
            baslik: "Motor ve Araç Tekniği",
            slug: "motor-ve-arac-teknigi",
            aciklama:
                "Motor bilginizi ölçmek ve sınavlara en iyi şekilde hazırlanmak için en güncel ve kapsamlı motor sorularıyla pratik yapın! 🚘🔧",
            butonYazisi: "Test Çöz",
            img: motorImg,
        },
        {
            id: 4,
            baslik: "Trafik adabı",
            slug: "trafik-adabi",
            aciklama:
                "Trafik adabı bilginizi ölçmek ve sınavlara en iyi şekilde hazırlanmak için en güncel ve kapsamlı trafik adabı sorularıyla pratik yapın! 🚦😊",
            butonYazisi: "Test Çöz",
            img: trafikAdabiImg,
        },
    ];

    return (
        <div className="container my-5 mt-5 mb-5">
            <h2 className="mb-4">Konu testleri</h2>
            <p className="text-muted mb-4">
                Her Konuya özel Testleri Buradan Bulabilirsiniz
            </p>
            <div className="row">
                {konular.map((konu) => (
                    <div key={konu.id} className="col-md-3 mb-4">
                        <div className="card h-100 text-center">
                            <div className="card-body">
                                <img
                                    src={konu.img}
                                    alt={konu.baslik}
                                    className="rounded-circle mb-3"
                                    style={{ width: "150px", height: "150px" }}
                                />
                                <h5 className="card-title">{konu.baslik}</h5>
                                <p className="card-text">{konu.aciklama}</p>
                                <div className="text-center">
                                    <Link to={`/test/${konu.slug}/${konu.id}`} className="btn btn-sade">
                                        {konu.butonYazisi} <BsArrowRight />
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TestBasliklar;
