import React from 'react';
import Banner from "../../Component/Banner";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";


const styles = {
    section: {
        // Buraya stil özellikleri ekleyin
    },
    headerText: {
        marginBottom: 1,
        fontSize:'24px',

    },
    text: {
        // Buraya metin stil özellikleri ekleyin
        marginBottom:20,
    }
};

const KullanimKosullari = () => {
    return (
        <div>

            <Helmet>
                <title>Kullanım Koşulları - Hizmet ve Kullanıcı Sözleşmesi</title>
                <meta name="description" content="Ehliyet Cenk Hoca web sitesini kullanırken uyulması gereken kurallar ve şartlar hakkında bilgi edinin. Kullanım Koşulları sayfamızda hizmet politikalarımızı öğrenin." />
                <meta name="keywords" content="kullanım koşulları, kullanıcı sözleşmesi, hizmet şartları, web sitesi kuralları, ehliyet testi kullanım şartları, hukuki şartlar" />

            </Helmet>

            <Banner title="Kullanım Koşulları"/>
            <Container className="mt-5">

                <h2 style={styles.headerText}>Kullanım Koşulları</h2>
                <p style={styles.text}>
                    Ehliyet Cenk Hoca Websitesi'nin kullanımı, demokratik değerlere ve insan haklarına saygıyı temel alır. Bu kullanım koşulları, uygulamanın kullanımını düzenleyen ve kullanıcıların haklarını koruyan bir çerçeve sunar.
                </p>


                <h2 style={styles.headerText}>Kullanıcı Sorumlulukları ve Hakları</h2>
                <p style={styles.text}>
                    Websiteyi kullanarak, kullanıcılar, demokratik değerlere ve toplumun genel çıkarlarına saygı göstermekle yükümlüdürler. Kullanıcılar, uygulamayı yasalara ve düzenlemelere uygun şekilde kullanmakla sorumludur ve diğer kullanıcıların haklarına saygı göstermelidirler.
                </p>

                <h2 style={styles.headerText}>Hizmetin Sınırları ve Garantiler</h2>
                <p style={styles.text}>
                    Ehliyet Cenk Hoca Websitesi, mevcut duruma bağlı olarak sunulur ve herhangi bir garanti vermez. Uygulamanın kesintisiz veya hatasız olacağına dair herhangi bir taahhütte bulunulmaz. Uygulamanın kullanımı tamamen kullanıcının kendi sorumluluğundadır.
                </p>

                <h2 style={styles.headerText}>İçerik ve Kullanım Kısıtlamaları</h2>
                <p style={styles.text}>
                    Websitenin içeriği, demokratik değerlere, insan haklarına ve toplumun genel çıkarlarına uygun olmalıdır. Herhangi bir şekilde yasa dışı, ahlaka aykırı veya zarar verici içerik yayınlamak veya paylaşmak yasaktır. Uygulama, kullanıcılara karşı herhangi bir ayrımcılığı veya şiddeti teşvik etmez.
                </p>

                <h2 style={styles.headerText}>Hizmetten Ayrılma Hakkı</h2>
                <p style={styles.text}>
                    Ehliyet Cenk Hoca Websitesi, herhangi bir zamanda kullanıcının hesabını askıya alma veya sonlandırma hakkını saklı tutar. Bu durumda, kullanıcıya herhangi bir bildirimde bulunulmaz ve uygulamanın nedenleri hakkında açıklama yapılabilir.
                </p>

                <h2 style={styles.headerText}>Değişiklikler ve Güncellemeler</h2>
                <p style={styles.text}>
                    Bu kullanım koşulları, websitenin kullanımıyla ilgili politika ve prosedürlerin bir özetini sunar. Ehliyet Cenk Hoca Uygulaması, bu koşulları dilediği zaman değiştirme veya güncelleme hakkını saklı tutar. Değişiklikler, kullanıcılara duyurulacak ve değişikliklerin yürürlüğe girmesinden sonra yeni kullanım koşulları geçerli olacaktır.
                </p>


            </Container>


        </div>
    );
};

export default KullanimKosullari;
