import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {Link} from "react-router-dom";

const CikmisSorular = () => {
    const [kategoriler, setKategoriler] = useState([]);
    const [showAll, setShowAll] = useState(false); // Verilerin tamamını gösterme durumu

    useEffect(() => {
        const apiUrl = "https://panel.mebehliyet.com/api/data/sorukategoriler";
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                setKategoriler(data);
            })
            .catch((error) => {
                console.error("API'den veri alınırken bir hata oluştu:", error);
            });
    }, []);

    const containerStyle = {
        padding: "40px 20px",
        borderRadius: "10px",
    };

    const cardStyle = {
        backgroundColor: "#ffffff",
        borderWidth:1,
        borderColor:'rgba(0,0,0,0.22)',
        borderRadius: "10px",
    };

    // Maksimum 5 veri göster, showAll true olursa tamamını göster
    const visibleKategoriler = showAll ? kategoriler : kategoriler.slice(0, 5);



    return (
        <div className="container text-center mt-5 mb-5" style={containerStyle}>
            <h2 className="fw-bold text-dark">📌 En Son Eklenen Ehliyet Soruları</h2>
            <p className="">Günlük güncellenen ehliyet sınav sorularıyla kendinizi test edin!</p>

            <div className="list-group mx-auto mt-4">
                {visibleKategoriler.map((q, index) => {
                    const baslik = q.slug; // SEF URL başlığı oluştur

                    return (
                        <div key={index} className="list-group-item d-flex justify-content-between align-items-center p-4 mt-3"
                             style={{backgroundColor: '#ffffff', borderWidth: '1px', borderColor: '#bcbcb9', borderRadius:10}}>
                            <div>
                                <h5 className="mt-1 mb-1 text-dark">{q.baslik}</h5>
                            </div>
                            <Link
                                to={`/${baslik}-${q.id}`}
                                className="btn btn-sade"
                            >
                                Çözmeye Başla
                            </Link>
                        </div>
                    );
                })}
            </div>

            {kategoriler.length > 5 && (
                <button
                    className="btn btn-sade mt-4 px-5 py-3 fw-bold"
                    onClick={() => setShowAll(!showAll)}
                >
                    {showAll ? "👆 Daha Azını Göster" : "📚 Daha Fazlasını Keşfet"}
                </button>
            )}
        </div>
    );
};

export default CikmisSorular;
