import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import axios from "axios"; // İkonlar için

const Sss = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [sssler, setSssler] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchBloglar = async () => {
            try {
                const response = await axios.get("https://panel.mebehliyet.com/api/data/sss"); // API endpoint buraya gelecek
                setSssler(response.data);
            } catch (error) {
                console.error("Blogları çekerken hata oluştu:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBloglar();
    }, []);





    const toggleSoru = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div style={{marginTop:'100px'}}  className="container">
            <h2 className="mb-4">Sıkça Sorulan Sorular (SSS)</h2>
            <p className="text-muted mb-4">
                Ehliyet sınavına dair merak ettiğiniz tüm soruların yanıtlarını burada bulabilirsiniz.
            </p>
            <div className="row">
                {sssler.map((soru, index) => (
                    <div key={soru.id} className="col-12 mb-3">
                        <div className="sss-card">
                            <div
                                className="sss-card-header"
                                onClick={() => toggleSoru(index)}
                            >
                                <h5>{soru.baslik}</h5>
                                {activeIndex === index ? (
                                    <BsChevronUp className="text-primary" />
                                ) : (
                                    <BsChevronDown className="text-secondary" />
                                )}
                            </div>
                            <div
                                className="sss-card-body"
                                style={{
                                    maxHeight: activeIndex === index ? "200px" : "0",
                                    opacity: activeIndex === index ? 1 : 0,
                                    padding: activeIndex === index ? "16px" : "0 16px",
                                }}
                            >
                                <p>{soru.icerik}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sss;
