import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Result from "./KonuTestResult";
import { useLocation, useParams } from "react-router-dom";

const KonuTestCoz = () => {
    const { id, slug } = useParams(); // URL'den ID ve başlığı al
    const location = useLocation();

    const formatTitle = (slug) => {
        // Küçük harfleri ve bozulmuş karakterleri düzeltmek için Türkçe harita
        const turkishCharMap = {
            "i̇": "İ", "i": "İ", "ı": "I", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç"
        };

        return slug
            .replace(/-/g, " ") // "-" karakterlerini boşluk ile değiştir
            .split(" ") // Kelimeleri ayır
            .map(word => {
                // Tüm kelimeyi önce küçük harfe çevir (Türkçeyi bozmayacak şekilde)
                word = word.toLowerCase();

                // İlk harfi kontrol et, Türkçeyse değiştir, değilse büyük harf yap
                const firstChar = word.charAt(0);
                const remainingChars = word.slice(1);

                const correctedFirstChar = turkishCharMap[firstChar] || firstChar.toUpperCase();

                return correctedFirstChar + remainingChars;
            })
            .join(" "); // Kelimeleri tekrar birleştir
    };




    const formattedBaslik = formatTitle(slug); // Başlığı düzenle

    const [sorular, setSorular] = useState([]);
    const [aktifSoruIndex, setAktifSoruIndex] = useState(0);
    const [secilenCevapIndex, setSecilenCevapIndex] = useState(null);
    const [geriBildirim, setGeriBildirim] = useState(null);
    const [sonucuGoster, setSonucuGoster] = useState(false);

    // Kullanıcı'nın yanıtlarını (doğru_mu) question_id bazlı bir objede saklıyoruz.
    // Örnek: { 101: { soru_id:101, dogru_mu:true }, 102: { soru_id:102, dogru_mu:false } }
    const [kullanıcıCevapları, setKullanıcıCevapları] = useState({});

    // 45 dakikalık sınav için örnek: 45 * 60 = 2700
    const [zamanlayıcı, setZamanlayıcı] = useState(2700);

    // 1) Soru verilerini çek
    useEffect(() => {
        const apiUrl = `https://panel.mebehliyet.com/api/data/webkonutesticek/${id}`;
        fetch(apiUrl)
            .then((res) => res.json())
            .then((data) => setSorular(data))
            .catch((err) => console.error("API Hatası:", err));
    }, [id]);

    // 2) Geri sayım (timer)
    useEffect(() => {
        if (zamanlayıcı > 0 && !sonucuGoster) {
            const interval = setInterval(() => {
                setZamanlayıcı((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else if (zamanlayıcı === 0) {
            // Süre bitince otomatik sınav biter
            setSonucuGoster(true);
        }
    }, [zamanlayıcı, sonucuGoster]);

    // 3) Bir cevaba tıklanınca
    const cevapSecildi = (index) => {
        if (sonucuGoster) return;

        setSecilenCevapIndex(index);

        const dogruCevapHarf = sorular[aktifSoruIndex].correct_option; // "A", "B", "C", "D"
        const dogruMu = index === dogruCevapHarf.charCodeAt(0) - 65;   // "A" -> 65

        const secilenHarf = ["A", "B", "C", "D"][index];

        // Kullanıcı cevabını kaydet
        const currentQId = sorular[aktifSoruIndex].question_id;
        setKullanıcıCevapları((prev) => ({
            ...prev,
            [currentQId]: {
                soru_id: currentQId,
                secilen_cevap: secilenHarf,
                dogru_mu: dogruMu,
            },
        }));
    };


    // 4) Sonraki soruya geç
    const sonrakineTıklandı = () => {

        // Sonraki index'e geç
        if (aktifSoruIndex < sorular.length - 1) {
            setAktifSoruIndex((prev) => prev + 1);
            setSecilenCevapIndex(null);
            setGeriBildirim(null);
        } else {
            // Son soru ise sınav biter
            setSonucuGoster(true);
        }
    };

    // 5) Sınavı bitir
    const sinaviBitir = () => {
        setSonucuGoster(true);
    };
    if (sonucuGoster) {
        // Sonuç bileşenine soruları ve cevapları gönderiyoruz
        return (
            <Result
                sorular={sorular}
                kullanıcıCevapları={kullanıcıCevapları}
                id={id}
                baslik={formattedBaslik}
            />
        );
    }

    // 6) Soru numarasına tıklandığında
    const soruNumarasinaGit = (index) => {
        if (index < 0 || index >= sorular.length) return;
        setAktifSoruIndex(index);
        setSecilenCevapIndex(null);
        setGeriBildirim(null);
    };

    // 7) Zamanı MM:SS formatına çevir
    const formatSure = (seconds) => {
        const dakika = Math.floor(seconds / 60);
        const saniye = seconds % 60;
        return `${dakika.toString().padStart(2, "0")}:${saniye
            .toString()
            .padStart(2, "0")}`;
    };

    // 8) Cevaplanan soru sayısı (Bar buna göre ilerlesin)
    const cevaplananSoruSayisi = Object.keys(kullanıcıCevapları).length;
    const ilerlemeYuzdesi = (cevaplananSoruSayisi / sorular.length) * 100;

    if (!sorular.length) {
        return (
            <Container fluid className="my-5 text-center">
                <h5>Sorular yükleniyor...</h5>
            </Container>
        );
    }

    // Mevcut soru
    const aktifSoru = sorular[aktifSoruIndex];

    return (
        <>

            <Helmet>
                <title>{formattedBaslik} - Ehliyet Testi ve Çıkmış Sorular</title>
                <meta name="description" content="{formattedBaslik} konusuna ait en güncel ehliyet sınav sorularını çözerek kendinizi test edin. Trafik, motor, ilk yardım ve diğer ehliyet konularında pratik yapın!" />
                <meta name="keywords" content="{formattedBaslik}, ehliyet testi, ehliyet sınavı, çıkmış ehliyet soruları, trafik soruları, motor soruları, ilk yardım sınavı, sürücü belgesi sınavı" />
            </Helmet>


            <Container fluid className="py-3 mt-5 mb-5" style={{ maxWidth: "1200px" }}>
                {/* Üst Kısım */}
                <Row className="align-items-center mb-2">
                    <Col md={8}>
                        <h4 className="fw-bold">
                            {formattedBaslik}
                        </h4>
                    </Col>
                    <Col md={4} className="text-end">
                        {/* Zaman Göstergesi */}
                        <span className="me-3 fs-5 fw-semibold">{formatSure(zamanlayıcı)}</span>
                        {!sonucuGoster && (
                            <Button variant="success" onClick={sinaviBitir}>
                                Sınavı Bitir
                            </Button>
                        )}
                    </Col>
                </Row>

                {/* İnce Progress Bar */}
                <Row>
                    <Col>
                        <div
                            style={{
                                height: "8px",
                                backgroundColor: "#e9ecef",
                                borderRadius: "4px",
                            }}
                        >
                            <div
                                style={{
                                    width: `${ilerlemeYuzdesi}%`,
                                    height: "100%",
                                    backgroundColor: "#0d6efd",
                                    transition: "width 0.3s",
                                    borderRadius: "4px",
                                }}
                            ></div>
                        </div>
                    </Col>
                </Row>



                <Row className="mt-4">
                    <Col>
                        <div
                            className="p-3"
                            style={{
                                background: "#fff",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                            }}
                        >
                            <h5 className="mb-3">
                                Soru {aktifSoruIndex + 1} / {sorular.length}
                            </h5>

                            {/* Soru Resmi (varsa) */}
                            {aktifSoru.resimyol && (
                                <div className="text-center mb-3">
                                    <Image
                                        src={`https://panel.mebehliyet.com/${aktifSoru.resimyol}`}
                                        fluid
                                        alt="Soru Resmi"
                                        style={{ maxHeight: "250px" }}
                                    />
                                </div>
                            )}

                            {aktifSoru.question_videoyol && (
                                <div className="text-center mb-3">
                                    <video
                                        src={`https://panel.mebehliyet.com/${aktifSoru.question_videoyol}`}
                                        controls
                                        style={{ maxWidth: "100%", maxHeight: "300px" }}
                                    >
                                        Tarayıcınız video formatını desteklemiyor.
                                    </video>
                                </div>
                            )}

                            <p
                                className="fw-semibold"
                                dangerouslySetInnerHTML={{
                                    __html: aktifSoru.question_text.replace(/\n/g, "<br>"),
                                }}
                            ></p>


                            {/* Cevap Şıkları */}
                            <Row className="g-3">
                                {["A", "B", "C", "D"].map((harf, index) => {
                                    // Metin ve resim bilgilerini al
                                    const secenekMetni = aktifSoru[`option_${harf}`];
                                    const secenekResim = aktifSoru[`option_${harf}_resimyol`];
                                    const dogruCevapHarf = aktifSoru.correct_option; // Ör: "A", "B", "C", "D"

                                    // Kullanıcının o soruya verdiği cevap (ör: "A", "B" vs.)
                                    const userCevap = kullanıcıCevapları[aktifSoru.question_id]?.secilen_cevap;

                                    // Varsayılan buton rengi
                                    let variant = "outline-secondary";

                                    // Eğer kullanıcı bu soruya cevap vermişse
                                    if (userCevap) {
                                        // Sorunun gerçek doğru cevabını yeşile boya
                                        if (harf === dogruCevapHarf) {
                                            variant = "success";
                                        }
                                        // Yanlış cevabı kırmızıya boya
                                        else if (harf === userCevap) {
                                            variant = "danger";
                                        }
                                    }

                                    return (
                                        <Col xs={12} md={6} key={harf}>
                                            <Button
                                                variant={variant}
                                                className="w-100 text-start text-dark"
                                                style={{
                                                    borderRadius: "8px",
                                                    minHeight: "60px",
                                                    fontWeight: "400",
                                                    // Kullanıcı ilgili soruya cevap vermişse veya 'sonucuGoster' açıksa butonlar kilitlenir
                                                    pointerEvents: (userCevap || sonucuGoster) ? "none" : "auto",
                                                }}
                                                onClick={() => cevapSecildi(index)}
                                            >
                                                <strong>{harf})</strong> {secenekMetni}

                                                {/* Eğer seçenek görseli varsa ekrana bas */}
                                                {secenekResim && (
                                                    <div className="mt-2">
                                                        <Image
                                                            src={`https://panel.mebehliyet.com/${secenekResim}`}
                                                            fluid
                                                            alt={`Seçenek ${harf} resmi`}
                                                            style={{ maxHeight: "120px" }}
                                                        />
                                                    </div>
                                                )}
                                            </Button>
                                        </Col>
                                    );
                                })}
                            </Row>


                            <Row className="mt-4">
                                <Col className="d-flex justify-content-between align-items-center">
                                    {/* Önceki Soru Butonu */}
                                    <Button
                                        variant="danger"
                                        onClick={() => soruNumarasinaGit(aktifSoruIndex - 1)}
                                        disabled={aktifSoruIndex === 0}
                                        className="px-2"
                                    >
                                        &laquo; Önceki
                                    </Button>


                                    {/* Sonraki Soru veya Sınavı Bitir Butonu */}
                                    {aktifSoruIndex < sorular.length - 1 ? (
                                        <Button
                                            variant="primary"
                                            onClick={sonrakineTıklandı}
                                            className="px-3"
                                        >
                                            Sonraki &raquo;
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="success"
                                            onClick={sinaviBitir}
                                            className="px-3"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            Sınavı Bitir
                                        </Button>
                                    )}

                                </Col>
                            </Row>



                        </div>
                    </Col>
                </Row>


                <Row className="mt-3">
                    <Col className="text-center">
                        {/* Geri */}
                        <Button
                            variant="outline-secondary"
                            onClick={() => soruNumarasinaGit(aktifSoruIndex - 1)}
                            disabled={aktifSoruIndex === 0}
                            className="me-2"
                        >
                            &laquo;
                        </Button>

                        {/* Orta Kısım: Soru Numaraları */}
                        {sorular.map((soru, i) => {
                            // Bu soruya cevap kaydı var mı?
                            const kayit = kullanıcıCevapları[soru.question_id];

                            let btnVariant = "outline-secondary"; // Hiç cevaplanmadı
                            if (i === aktifSoruIndex) btnVariant = "dark"; // Şu anki soru
                            if (kayit?.dogru_mu === true) btnVariant = "success";
                            if (kayit?.dogru_mu === false) btnVariant = "danger";

                            return (
                                <Button
                                    key={soru.question_id}
                                    variant={btnVariant}
                                    onClick={() => soruNumarasinaGit(i)}
                                    style={{ width: "40px", margin: "2px" }}
                                >
                                    {i + 1}
                                </Button>
                            );
                        })}

                        {/* İleri */}
                        <Button
                            variant="outline-secondary"
                            onClick={() => soruNumarasinaGit(aktifSoruIndex + 1)}
                            disabled={aktifSoruIndex === sorular.length - 1}
                            className="ms-2"
                        >
                            &raquo;
                        </Button>
                    </Col>
                </Row>
            </Container>


        </>
    );
};

export default KonuTestCoz;
