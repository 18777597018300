import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";



const Bloglar = () => {
    const [bloglar, setBloglar] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBloglar = async () => {
            try {
                const response = await axios.get("https://panel.mebehliyet.com/api/data/bloglarindex");
                if (Array.isArray(response.data)) {
                    setBloglar(response.data);
                } else {
                    console.error("Blog verisi beklenen formatta değil:", response.data);
                }
            } catch (error) {
                console.error("Blogları çekerken hata oluştu:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBloglar();
    }, []);

    if (loading) {
        return (
            <div className="container text-center my-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                </div>
            </div>
        );
    }

    return (
        <div style={{ marginTop: "100px" }} className="container">
            <h2 className="text-center mb-4">Bloglarımız</h2>
            <p className="text-center mb-5">
                Güncel bilgiler ve rehber yazılar için bloglarımıza göz atın.
            </p>
            <div className="row g-4">
                {bloglar.length > 0 ? (
                    bloglar.map((blog) => {
                        const sefBaslik = blog.slug; // SEF URL başlığı oluştur

                        return (
                            <div key={blog.id} className="col-md-4">
                                <div className="card h-100 shadow-sm">
                                    {blog.resimyol && (
                                        <img
                                            src={`https://panel.mebehliyet.com/${blog.resimyol}`}
                                            className="card-img-top"
                                            alt={blog.title || "Blog görseli"}
                                        />
                                    )}
                                    <div className="card-body">
                                        <h5 className="card-title">{blog.title || "Başlıksız Blog"}</h5>
                                        <p className="card-text">{blog.aciklama || "Açıklama bulunmamaktadır."}</p>

                                        <div className="text-left mb-3 mt-2">
                                            <Link to={`/blog/${sefBaslik}-${blog.id}`} className="btn btn-sade">
                                                Devamını oku <BsArrowRight />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p className="text-center">📌 Şu an için blog bulunmamaktadır.</p>
                )}
            </div>
        </div>
    );
};

export default Bloglar;
