import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    Image,
    Button,
} from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";

// Görseller (örnek)
import ilkYardimImg from "../../assets/kategoriler/ilkyardim.png";
import trafikImg from "../../assets/kategoriler/trafikvecevre.png";
import motorImg from "../../assets/kategoriler/motorveteknik.png";
import trafikAdabiImg from "../../assets/kategoriler/trafikadabi.png";
import {useLocation, useParams} from "react-router-dom";

import kaybetme from "../../assets/sonuc/1.png";
import fenadegil from "../../assets/sonuc/2.png";
import cokiyi from "../../assets/sonuc/3.png";
import mukemmel from "../../assets/sonuc/4.png";
import {Helmet} from "react-helmet";

const KonuTestResult = ({ sorular, kullanıcıCevapları }) => {
    // 1) Bicim -> Metin çevirme
    const bicimMap = {
        1: "İlk Yardım",
        2: "Motor ve Teknik",
        3: "Trafik Adabı",
        4: "Trafik ve Çevre",
    };

    // 2) Bicim -> Resim çevirme
    const bicimImages = {
        1: ilkYardimImg,
        2: motorImg,
        3: trafikAdabiImg,
        4: trafikImg,
        Genel: ilkYardimImg,
    };
    const location = useLocation();

    const { id, slug } = useParams(); // URL'den ID ve başlığı al

    const formatTitle = (slug) => {
        // Küçük harfleri ve bozulmuş karakterleri düzeltmek için Türkçe harita
        const turkishCharMap = {
            "i̇": "İ", "i": "İ", "ı": "I", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç"
        };

        return slug
            .replace(/-/g, " ") // "-" karakterlerini boşluk ile değiştir
            .split(" ") // Kelimeleri ayır
            .map(word => {
                // Tüm kelimeyi önce küçük harfe çevir (Türkçeyi bozmayacak şekilde)
                word = word.toLowerCase();

                // İlk harfi kontrol et, Türkçeyse değiştir, değilse büyük harf yap
                const firstChar = word.charAt(0);
                const remainingChars = word.slice(1);

                const correctedFirstChar = turkishCharMap[firstChar] || firstChar.toUpperCase();

                return correctedFirstChar + remainingChars;
            })
            .join(" "); // Kelimeleri tekrar birleştir
    };

    const formattedBaslik = formatTitle(slug); // Başlığı düzenle



    // 3) Soruları bicim bazında grupluyoruz
    const bicimGruplari = {};
    sorular.forEach((soru) => {
        const qId = soru.question_id;
        const bicim = soru.bicim || "Genel";
        if (!bicimGruplari[bicim]) {
            bicimGruplari[bicim] = { toplam: 0, dogru: 0 };
        }
        bicimGruplari[bicim].toplam += 1;

        const userCevap = kullanıcıCevapları[qId];
        if (userCevap?.dogru_mu) {
            bicimGruplari[bicim].dogru += 1;
        }
    });

    // 4) Puanları hesapla
    let genelDogru = 0;
    let genelToplam = 0;
    const bicimSonuclari = Object.entries(bicimGruplari).map(([bicim, deger]) => {
        const puan = Math.round((deger.dogru / deger.toplam) * 100);
        genelDogru += deger.dogru;
        genelToplam += deger.toplam;
        return {
            bicim,
            dogru: deger.dogru,
            toplam: deger.toplam,
            puan,
        };
    });

    // GENEL PUAN
    const genelPuan =
        genelToplam > 0 ? Math.round((genelDogru / genelToplam) * 100) : 0;

    // 5) Genel mesaj
    let mesaj = "";
    let alertVariant = "danger";
    let imageUrl = "https://via.placeholder.com/150?text=Maalesef";

    if (genelPuan < 70) {
        mesaj = `Maalesef ${genelPuan} puanla kaldınız. Biraz daha çalışmanız gerek!`;
        alertVariant = "danger";
        imageUrl = kaybetme;
    } else if (genelPuan < 80) {
        mesaj = `Fena değil, ${genelPuan} puan aldınız! Biraz daha çalışabilirsiniz.`;
        alertVariant = "warning";
        imageUrl = fenadegil;
    } else if (genelPuan < 90) {
        mesaj = `Tebrikler, ${genelPuan} puan! Çok iyi bir sonuç.`;
        alertVariant = "info";
        imageUrl = cokiyi;
    } else {
        mesaj = `Harika! ${genelPuan} puanla mükemmelsiniz!`;
        alertVariant = "success";
        imageUrl = mukemmel;
    }

    const getOneriMesaji = (puan) => {
        if (puan < 50) {
            return "Bu konuda daha fazla çalışmalısınız.";
        } else if (puan < 70) {
            return "Fena değil, biraz daha dikkat etmelisiniz.";
        } else if (puan < 90) {
            return "İyi sonuç, bu konuda ilerleme kaydettiniz!";
        } else {
            return "Harika, bu konuda çok başarılısınız!";
        }
    };

    // Konu konu progress color
    const getProgressColor = (puan) => {
        if (puan < 50) return "#dc3545";  // kırmızı
        if (puan < 70) return "#ffc107";  // sarı
        if (puan < 90) return "#17a2b8";  // mavi
        return "#28a745";                // yeşil
    };

    // GENEL PUAN color
    const getGenelColor = (puan) => {
        return puan < 70 ? "#dc3545" : "#28a745"; // Örnek: 70 altında kırmızı, üstünde yeşil
    };

    return (
        <Container className="my-4 mt-5">
            <Helmet>
                <title>Sınav Sonuçları</title>
            </Helmet>
            <style>{`
                .bicim-kart {
                  border: 1px solid #eee;
                  border-radius: 8px;
                }
                .bicim-kart .card-header {
                  background-color: #f8f9fa;
                  font-weight: 600;
                }
                .bicim-img {
                  max-width: 100px;
                  margin: 0 auto;
                  display: block;
                }
            `}</style>

            {/* Üst Başlık */}
            <Row className="mb-4 mt-4">
                <Col>
                    <h3 className="fw-bold">{formattedBaslik} Sonuçlarınız</h3>
                </Col>
            </Row>

            <Row>

                {/* SAĞ: Genel Sonuç */}
                <Col lg={8}>
                    <div className="text-center border p-3">
                        {/* Genel Sonuç Görseli */}
                        <Image
                            src={imageUrl}
                            alt="Result Visual"
                            style={{ maxWidth: "200px" }}
                            className="my-2"
                        />



                        {/* “Tüm puan” metni vs. */}
                        <h4 className="mt-3">
                            Toplamda <strong>{genelPuan}</strong> puan aldınız.
                        </h4>
                        <p className="text-muted mb-1">
                            (Sınavı geçmek için minimum <strong>70</strong> puan gereklidir.)
                        </p>
                    </div>



                    <Alert
                        variant={alertVariant}
                        className=" py-4 shadow-sm mt-3"
                    >
                        <h4 className="mb-2">{mesaj}</h4>

                        {bicimSonuclari.map((item) => {
                            const bicimLabel = bicimMap[item.bicim]
                                ? bicimMap[item.bicim]
                                : "Genel";
                            const bicimImage = bicimImages[item.bicim]
                                ? bicimImages[item.bicim]
                                : bicimImages["Genel"];

                            return (

                                <p
                                    className="mt-2"
                                    style={{
                                        backgroundColor:
                                            item.puan < 50
                                                ? "#f8d7da"
                                                : item.puan < 70
                                                    ? "#fff3cd"
                                                    : item.puan < 90
                                                        ? "#d1ecf1"
                                                        : "#d4edda",
                                        padding: "8px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <strong>{bicimLabel}:</strong>{" "}
                                    {getOneriMesaji(item.puan)}
                                </p>
                            );
                        })}
                    </Alert>

                    <div className="text-center">
                        <Button variant="primary" href="/">
                            Anasayfaya Dön
                        </Button>
                    </div>
                </Col>
                {/* SOL: Konu Kartları */}
                <Col lg={4} className="mb-4">
                    <Row className="g-3">
                        {bicimSonuclari.map((item) => {
                            const bicimLabel = bicimMap[item.bicim]
                                ? bicimMap[item.bicim]
                                : "Genel";
                            const bicimImage = bicimImages[item.bicim]
                                ? bicimImages[item.bicim]
                                : bicimImages["Genel"];

                            return (
                                <Col xs={12} key={item.bicim}>
                                    <Card className="text-center bicim-kart h-100 shadow-sm">
                                        <Card.Header>{bicimLabel}</Card.Header>
                                        <Card.Body>
                                            <Image
                                                src={bicimImage}
                                                alt={bicimLabel}
                                                className="bicim-img mb-3"
                                                roundedCircle
                                            />

                                            <p>
                                                <strong>Toplam : </strong>{item.toplam}
                                            </p>
                                            <Card.Text>
                                                <strong>Doğru:</strong> {item.dogru}
                                            </Card.Text>




                                        </Card.Body>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </Col>

            </Row>
        </Container>
    );
};

export default KonuTestResult;
