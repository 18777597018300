import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaTwitter, FaInstagram, FaFacebook,FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Eğer kullanıyorsanız

const Footer = () => {
    return (
        <footer style={{ background: '#023D62', paddingTop: '50px',marginTop:'50px', paddingBottom: '10px' }}>
            <Container>
                <Row>
                    <Col md={5}>
                        <div className="">
                            <h5 style={{ color: '#ffffff' }}>Ehliyet Cenk Hoca</h5>
                            <p style={{ color: '#ffffff' }}>Ehliyet Cenk Hoca, sürücü adaylarına yönelik eğitim materyalleri sunan bir platformdur. </p>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="">
                            <h5 style={{ color: '#ffffff' }}>Hızlı Linkler</h5>
                            <ul className="list-unstyled">
                                <li><Link to="/ehliyet-siniflari" style={{ color: '#ffffff', textDecoration: 'none' }}>Ehliyet Sınıfları</Link></li>
                                <li><Link to="/hiz-limitleri" style={{ color: '#ffffff', textDecoration: 'none' }}>Hız Limitleri</Link></li>
                                <li><Link to="/trafik-isaretleri" style={{ color: '#ffffff', textDecoration: 'none' }}>Trafik İşaretleri</Link></li>
                                <li><Link to="/ders-notlari" style={{ color: '#ffffff', textDecoration: 'none' }}>Ders Notları</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="">
                            <h5 style={{ color: '#ffffff' }}>Bilgi Sayfaları</h5>
                            <ul className="list-unstyled" style={{ textAlign: '' }}>
                                <li><Link to="/gizlilik-politikasi" style={{ color: '#ffffff', textDecoration: 'none' }}>Gizlilik Politikası</Link></li>
                                <li><Link to="/kullanim-kosullari" style={{ color: '#ffffff', textDecoration: 'none' }}>Kullanım Koşulları</Link></li>
                                <li><Link to="/cerez-politikasi" style={{ color: '#ffffff', textDecoration: 'none' }}>Çerez Politikası</Link></li>
                            </ul>
                        </div>
                    </Col>


                </Row>
                <hr style={{ borderColor: '#ffffff' }} />
                <Row className="mt-4">
                    <Col className="text-center">
                        <div style={{ color: '#ffffff' }}>
                            <Link to="https://www.instagram.com/ehliyetcenkhoca" style={{ color: '#ffffff', marginRight: '10px', textDecoration: 'none' }}>
                                <FaInstagram size="1.5em" />
                            </Link>
                            <Link to="https://www.youtube.com/@ehliyetsinavsorulari1" style={{ color: '#ffffff', marginRight: '10px', textDecoration: 'none' }}>
                                <FaYoutube size="1.5em" />
                            </Link>
                            <Link to="/facebook" style={{ color: '#ffffff', marginRight: '10px', textDecoration: 'none' }}>
                                <FaFacebook size="1.5em" />
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="text-center">
                        <p style={{ color: '#ffffff' }}>Telif Hakkı &copy; {new Date().getFullYear()} Ehliyet Cenk Hoca</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
