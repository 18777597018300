import React from 'react';
import {Helmet} from "react-helmet";
import CikmisSorular from "./Component/CikmisSorular";
import TestBasliklar from "./Component/TestBasliklar";
import Sss from "./Component/Sss";
import Bloglar from "./Component/Bloglar";




const Home = () => {
    return (
        <div>
            <Helmet>
                <title>Çıkmış Ehliyet Sınav Soruları Çöz - Cenk Hoca</title>
                <meta name="description" content="Ehliyet sınavına hazırlık için en güncel çıkmış sorular, konu testleri ve eğitim içerikleri burada! Trafik, motor, ilk yardım ve trafik adabı konularında bilgi sahibi olun." />
                <meta name="keywords" content="ehliyet sınavı, ehliyet çıkmış sorular, ehliyet testi, trafik bilgisi, motor bilgisi, ilk yardım soruları, ehliyet deneme sınavı" />
            </Helmet>
            <div>
                <CikmisSorular/>
                <TestBasliklar/>
                <Sss/>
                <Bloglar/>

            </div>


        </div>
    );
};

export default Home;
