import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Banner from "../../Component/Banner";

const TestKategoriPage = () => {
    const [kategoriler, setKategoriler] = useState([]);
    const [baslik, setBaslik] = useState("");
    const [showAll, setShowAll] = useState(false);
    const [loading, setLoading] = useState(true); // Yüklenme durumu ekledik
    const { slug, id } = useParams(); // URL'den slug ve id'yi al

    // Slug'ı düzgün göstermek için fonksiyon
    const formatSlug = (text) => {
        return text
            .replace(/-/g, " ") // "-" yerine boşluk koy
            .replace(/\b\w/g, (char) => char.toUpperCase()); // İlk harfleri büyüt
    };

    useEffect(() => {
        setLoading(true);
        const apiUrl = `https://panel.mebehliyet.com/api/data/konutestcat/${id}`;

        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                if (data?.veriler?.length > 0) {
                    setKategoriler(data.veriler);
                } else {
                    setKategoriler([]); // Eğer boşsa, boş array olarak ayarla
                }
                setBaslik(data.kategori_adi || "Kategori Testleri");
            })
            .catch((error) => {
                console.error("API Hatası:", error);
                setKategoriler([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    const visibleKategoriler = showAll ? kategoriler : kategoriler.slice(0, 10);

    return (
        <div>
            <Helmet>
                <title>{baslik} - Ehliyet Testleri ve Çıkmış Sorular</title>

                <meta name="description" content="{baslik} testini çözerek ehliyet sınavına hazırlanabilirsiniz. En güncel trafik, motor ve ilk yardım testleri ile bilginizi ölçün!" />

                <meta name="keywords" content="{baslik}, ehliyet testi, ehliyet sınavı, çıkmış ehliyet soruları, trafik bilgisi, motor bilgisi, ilk yardım soruları, sürücü belgesi sınavı" />
            </Helmet>

            <Banner title="Konuya Ait Testler" />
            <div className="container text-center mt-4 mb-2">
                <h2 className="fw-bold text-dark">📌 {formatSlug(slug)}</h2>

                {/* Yüklenme Durumu */}
                {loading ? (
                    <p>📡 Testler yükleniyor...</p>
                ) : kategoriler.length > 0 ? (
                    <div className="list-group mx-auto mt-4">
                        {visibleKategoriler.map((q, index) => {
                            const testSlug = q.baslik
                                .toLowerCase()
                                .replace(/ğ/g, "g")
                                .replace(/ü/g, "u")
                                .replace(/ş/g, "s")
                                .replace(/ı/g, "i")
                                .replace(/ö/g, "o")
                                .replace(/ç/g, "c")
                                .replace(/[^a-z0-9]+/g, "-")
                                .replace(/^-+|-+$/g, "");

                            return (
                                <div key={index} className="list-group-item d-flex justify-content-between align-items-center p-4 mt-3" style={{backgroundColor: '#ffffff',
                                    borderWidth: '1px', borderColor: '#bcbcb9', borderRadius:10}}>

                                    <div>
                                        <h5 className="mt-1 mb-1 text-dark">{q.baslik}</h5>
                                    </div>
                                    <Link to={`/konu-test-coz/${testSlug}/${q.id}`} className="btn btn-sade">
                                        Çözmeye Başla
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p>⚠️ Bu kategoriye ait test bulunamadı.</p>
                )}

                {/* Daha fazla / daha az göster butonu */}
                {kategoriler.length > 10 && (
                    <button
                        className="btn btn-sade mt-4 px-5 py-3 fw-bold"
                        onClick={() => setShowAll(!showAll)}
                    >
                        {showAll ? "👆 Daha Azını Göster" : "📚 Daha Fazlasını Keşfet"}
                    </button>
                )}
            </div>
        </div>
    );
};

export default TestKategoriPage;
